import { useEffect, useState,ChangeEvent } from "react";
import "./table.css";
import axios from "axios";
import generatePDF from "../../utils/generatePdf";
import { coopsData } from "../../utils/coopsData";
import {
  Box,
  Button,
  Grid,
  TextField,
  Modal,
  Typography,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Switch
} from "@mui/material";
import { getLocalTime } from "../../utils/dateTimeByTZ";
import { jsPDF } from "jspdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import React, { CSSProperties } from "react";
const moment = require('moment-timezone');

interface FormsData {
  _id: string;
  title: string;
  username: string;
  date: string;
  actionRequired: object;
  actionRequiredCondition: boolean;
  isChecked: boolean;
  values: any;
  timezone:any;
}

const SubmissionList = () => {
  const BASE_URL = process.env.REACT_APP_API_KEY;

  const today = new Date().toISOString().split("T")[0];

  const last7Days = new Date();
  last7Days.setDate(last7Days.getDate() - 7);

  const last7DaysFormatted = last7Days.toISOString().split("T")[0];
  const [username, setUsername] = useState("");
  const [formsData, setFormsData] = useState<FormsData[]>([]);



  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [disable, setDisable] = useState(false);
  const [searchFromVal, setSearchFormVal] = useState("");
  const [searchUserVal, setSearchUserVal] = useState("");
  const [fromDate, setFromDate] = useState(last7DaysFormatted);
  const [toDate, setToDate] = useState(today);
  const [clear, setClear] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [arPatchId, setArPatchId] = useState(0);
  const [isEditable, setIsEditable] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [all, setAll] = useState(true);
  const [coopData, setCoopData] = useState({ systemList: [] });
  const [showCoopsModal, setShowCoopsModal] = useState(false);
  const [selectedCoopKeys, setSelectedCoopKeys] = useState<string[]>(
    coopData?.systemList?.map((coop: any) => coop?.systemNumber)
  );
  const [userSystemNum, setUserSystemNum] = useState("");

  const [formData, setFormData] = useState({
    order: "",
    actionTaken: "",
    correctedBy: "",
    dateCorrected: "",
    comments: "",
    submittedby: "",
  });
  const [formNames, setFormsNames] = useState([]);
  const [showAdvance, setShowAdvance] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [advInputs, setAdvInputs] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [deleteFormList, setDelFormList] = useState<any>([]);
  const [selectedFormData, setselectedFormData] = useState<any>([]);
  const [selectedAllForm, setSelectedAllForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [role, setRole] = useState("");
  const [isActionNeeded, setIsActionNeeded] = useState(false);
  const handleActionNeededToggle = (event: ChangeEvent<HTMLInputElement>) => { setIsActionNeeded(event.target.checked); };



  const spinnerStyle: CSSProperties = {
    display: "none",
    border: "5px solid black",
    borderTop: "7px solid white ",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "spin 1s linear infinite",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  };

  const showToast = (message: string, type: "success" | "error") => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const closeCoopsModal = () => {
    if (selectedCoopKeys.length == 0) {
      showToast("Please Select At Least One Coop List", "error");
    } else {
      setShowCoopsModal(false);
    }
  };

  const openCoopsModal = () => {
    setShowCoopsModal(true);
  };
  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchEmpty = () => {
    setSearchTerm("");
  };

  const filteredCoopData = searchTerm
    ? coopData && coopData.systemList
      ? coopData.systemList
        .filter(
          (coop: any) =>
            coop.systemName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            coop.systemNumber.toString().includes(searchTerm)
        )
        .sort(
          (a: any, b: any) =>
            +selectedCoopKeys.includes(b.systemNumber) -
            +selectedCoopKeys.includes(a.systemNumber)
        )
      : []
    : coopData && coopData.systemList
      ? coopData.systemList
        .slice()
        .sort(
          (a: any, b: any) =>
            +selectedCoopKeys.includes(b.systemNumber) -
            +selectedCoopKeys.includes(a.systemNumber)
        )
      : [];
  const handleCheckboxChange = (coopKey: string) => {
    // Toggle the selected state for the coop key
    setSelectedCoopKeys((prevSelected) =>
      prevSelected.includes(coopKey)
        ? prevSelected.filter((key) => key !== coopKey)
        : [...prevSelected, coopKey]
    );
  };
  const isAllSelected = selectedCoopKeys.length === coopData.systemList.length;

  useEffect(() => {
    async function fetchData() {
      const userName = localStorage.getItem("userName");
      setUsername(userName || "");
    }
    fetchData();
    fetchNames();
    const role = localStorage.getItem("role");
    setRole(role || "")
    if (role === "superadmin") {
      fetchCoops();
    }
  }, []);

  const fetchCoops = async () => {
    const coopData = await coopsData();
    const allCoopKeys = coopData.systemList.map(
      (coop: any) => coop.systemNumber
    );
    setSelectedCoopKeys(allCoopKeys);
    setCoopData(coopData);
    setAll(true)
  };

  useEffect(() => {
    fetchForms(); // Call fetchForms during initial mount and when page changes
  }, [page, clear]);

  const handleAllCheckboxChange = () => {
    if (selectedCoopKeys.length === coopData.systemList.length) {
      // When deselecting all, keep the userSystemNum selected
      // const newUserSystemNumSelected = userSystemNum ? [userSystemNum] : [];
      setSelectedCoopKeys([]);
      setAll(false);
    } else {
      // When selecting all, include all coop keys plus the userSystemNum if not already included
      const allCoopKeys = coopData.systemList.map(
        (coop: any) => coop.systemNumber
      );
      // if (userSystemNum && !allCoopKeys.includes(userSystemNum)) {
      //   allCoopKeys.push(userSystemNum);
      // }
      setSelectedCoopKeys(allCoopKeys);
      setAll(true);
    }
  };

  const fetchForms = async () => {
    setLoading(true);
    setFormsData([]);
    const token = localStorage.getItem("authtoken") || "";
    const authtokenfederated = localStorage.getItem("authtoken-federated") || "";

    const systemNumber = localStorage.getItem("systemNumber") || "";
    const role = localStorage.getItem("role") || "";

    setUserSystemNum(systemNumber)

    try {
      let advquery = "";
      let advFields = "";
      for (let param in advInputs) {
        if (advInputs[param] !== "") {
          advquery += `&${param}=${advInputs[param]}`;
          advFields += `${param},`;
        }
      }
      advFields = advFields.slice(0, -1);
      setSelectedCoopKeys((prevSysNum) => {
        if (!prevSysNum.includes(systemNumber) && role !== "superadmin") {
          return [...prevSysNum, systemNumber];
        }
        return prevSysNum;
      });
      let sysNum: string[] = [];
      if (selectedCoopKeys.length === 0 && role === "superadmin") {
        const allCoopKeys = coopData.systemList.map(
          (coop: any) => coop.systemNumber
        );
        setSelectedCoopKeys(allCoopKeys);
        setAll(true);
      }

      if (role !== "superadmin") {
        sysNum = [systemNumber]
      } else {
        if (!all) {
          sysNum = selectedCoopKeys
        } else if (all) {
          sysNum = ['all']
        }
      }

      // let route = `formsubmit/getSubmittedForms/1?systemNumber=${systemNumber}&page=${page}&username=${searchUserVal}&title=${searchFromVal}`;
      let route = `formsubmit/getSubmittedForms/1?systemNumber=${sysNum.toString()}&page=${page}&username=${searchUserVal}&title=${searchFromVal}&fromDate=${fromDate}&toDate=${toDate}&actionrequired=${isActionNeeded}`;
      if (advFields !== "") {
        route += `&advFields=${advFields}${advquery}`;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          federatedtoken: authtokenfederated,
        },
      };
      const response = await axios.get(`${BASE_URL}/${route}`, config);
      response?.data?.results.filter((form: any) => {

        if (form.values.isAttentionNeeded === "yes") {
          form.actionRequiredCondition = true;
        } else {
          form.actionRequiredCondition = false;
        }
        form.values = JSON.stringify(form.values);

        if (deleteFormList.indexOf(form._id) !== -1) {
          form.isChecked = true;
        } else {
          form.isChecked = false;
        }
        return form;
      });
      setFormsData(response?.data?.results);
      setTotalPages(response?.data?.totalPages);

      let selectAllFormCheck = response?.data?.results.every(
        (obj: any) => obj.isChecked
      );
      setSelectedAllForm(selectAllFormCheck);

      if (searchFromVal !== "" && response?.data?.results?.length !== 0) {
        setShowAdvance(true);

        let firstFourValues = response?.data?.results[0]?.firstFourValues || {};

        let updatedAdvInputs = { ...advInputs };
        for (let key in firstFourValues) {
          if (firstFourValues.hasOwnProperty(key)) {
            updatedAdvInputs[key] = advInputs[key] || firstFourValues[key];
          }
        }

        setAdvInputs(updatedAdvInputs);
      }
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchNames = async () => {
    const systemNumber = localStorage.getItem("systemNumber");
    const token = localStorage.getItem("authtoken") || "";
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/form/by-system/${systemNumber}`,
        config
      );
      setFormsNames(response?.data?.forms);
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  };

  function convertToTimeZone(date:any, timeZone:any) {
    const formattedDate = moment(date).tz(timeZone).format('MM-DD-YYYY HH:mm:ss');
    const timeZoneAbbreviation = moment.tz(timeZone).zoneAbbr();
    return `${formattedDate} ${timeZoneAbbreviation}`; // Append the timezone abbreviation
  }

  const capitalizeFirstLetter = (string: any) => {
    const val = string?.charAt(0)?.toUpperCase() + string?.slice(1);
    return val
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleFormChange = (e: any) => {
    setIsActionNeeded(false);
    setSearchFormVal(e);
    setShowAdvance(false);
    setAdvInputs({});
    setShowInputs(false);
  };

  const handleUserChange = (e: any) => {
    const value = e.replace(/\s+/g, "");
    setSearchUserVal(value);
    setShowAdvance(false);
    setAdvInputs({});
    setShowInputs(false);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setPage(1);
    setDelFormList([]);
    setselectedFormData([]);
    setSelectedAllForm(false);
    fetchForms();
  };
  const handleClearSearch = async () => {
    setPage(1);
    setSearchFormVal("");
    setSearchUserVal("");
    setShowAdvance(false);
    setAdvInputs({});
    setShowInputs(false);
    setDelFormList([]);
    setselectedFormData([]);
    setSelectedAllForm(false);
    if (role === "superadmin") {
      const allCoopKeys = coopData.systemList.map(
        (coop: any) => coop.systemNumber
      );
      setSelectedCoopKeys(allCoopKeys);
      setAll(true)
    } else {
      setSelectedCoopKeys([userSystemNum]);
      setAll(false)
    }
    setClear(!clear);
  };
  const handleShowAdvanceInputs = async () => {
    setShowInputs(!showInputs);
  };

  const handlePdf = (form: any) => async () => {
    setDisable(true);
    const token = localStorage.getItem("authtoken") || "";
    try {
      const parentId = form.parentId;
      const valuesId = form._id;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const fetchFormResponse = await axios.get(
        `${BASE_URL}/form/${parentId}`,
        config
      );
      const fetchValuesResponse = await axios.get(
        `${BASE_URL}/formsubmit/${valuesId}`,
        config
      );

      if (
        fetchFormResponse?.data?.success &&
        fetchValuesResponse?.data?.success
      ) {
        const htmlContent = generatePDF(
          fetchFormResponse?.data?.form?.values,
          fetchValuesResponse?.data?.form
        );

        var doc = new jsPDF();

        const container = document.createElement("div");
        container.innerHTML = htmlContent;
        document.body.appendChild(container);

        const name =
          form?.username.charAt(0).toUpperCase() + form?.username.slice(1);

        doc.html(container, {
          callback(doc) {
            let date;
            if (fetchValuesResponse?.data?.form && fetchValuesResponse?.data?.form.timezone) {
              date = moment(fetchValuesResponse?.data?.form.date).tz(fetchValuesResponse?.data?.form.timezone).format('MM-DD-YYYY HH:mm:ss');
            } else if (fetchValuesResponse?.data?.form && !fetchValuesResponse?.data?.form.timezone) {
              const localTime = moment.utc(fetchValuesResponse?.data?.form.date).local()
              date = localTime.format('MM-DD-YYYY HH-mm-ss')
            }
            doc.save(`${form.title} - ${name} - ${date}.pdf`);
          },
          margin: [10, 10, 10, 10],
          autoPaging: "text",
          x: 0,
          y: 0,
          width: 190,
          windowWidth: 675,
        });
        document.body.removeChild(container); // Clean up the DOM
      } else {
        console.error(
          "PDF generation failed: Form data could not be fetched successfully."
        );
      }
    } catch (error) {
      console.error("Error generating or downloading PDF:", error);
    } finally {
      setDisable(false);
    }
  };

  const handleCsv = (form: any) => async () => {
    setDisable(true);
    const token = localStorage.getItem("authtoken") || "";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const valuesId = form._id;
      const fetchValuesResponse = await axios.get(
        `${BASE_URL}/formsubmit/${valuesId}`,
        config
      );

      let csvContent = "Label,Value\n";

      // Iterate through the object and append each key-value pair to the CSV string
      for (const [key, value] of Object.entries(
        fetchValuesResponse.data.form.values
      )) {
        // Check if the value is a string that starts with "data:image/png;base64," or if it's an object
        if (
          typeof value === "string" &&
          value.startsWith("data:image/png;base64,")
        ) {
          continue; // Skip base64 encoded images
        } else if (typeof value === "object") {
          continue; // Skip objects
        }

        // Append the entry to the CSV string
        csvContent += `"${key}","${value}"\n`;
      }

      // Convert the CSV string to a Blob
      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create an anchor tag to trigger the download
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "export.csv");
      document.body.appendChild(link); // Required for Firefox

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDisable(false);
    } catch (error) {
      setDisable(false);
      console.error("Error generating or downloading CSV:", error);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAROpen = async (e: any) => {
    setValidationErrors({});
    setArPatchId(e?._id);
    const isEdit = typeof e?.actionRequired === "object" ? false : true;
    setIsEditable(isEdit);
    setFormData(typeof e?.actionRequired === "object" ? e?.actionRequired : {});
    handleOpenModal();
  };

  const handleARSend = async (e: any) => {
    const token = localStorage.getItem("authtoken");
    const authtokenfederated = localStorage.getItem("authtoken-federated");
    formData["submittedby"] = username;

    const response = await axios.patch(
      `${BASE_URL}/formsubmit/update/${arPatchId}`,
      { actionRequired: formData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          federatedtoken: authtokenfederated,
        },
      }
    );
    if (response.data.success === true) {
      setFormData(response.data.updatedDraftForm.actionRequired);
      setIsEditable(false);
      // handleCloseModal()
      fetchForms();
    } else {
      console.log("error");
    }
  };

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;

    // Create an updated form data object
    const updatedFormData = {
      ...formData,
      [id]: value, // Use the id to set the correct field in your state object
    };

    // Update the formData state
    setFormData(updatedFormData);

    const errors: any = {};
    if (!updatedFormData.order) {
      errors.order = "Order is required";
      setIsFormValid(false);
    }
    if (!updatedFormData.actionTaken) {
      errors.actionTaken = "Action Taken is required";
      setIsFormValid(false);
    }
    if (!updatedFormData.correctedBy) {
      errors.correctedBy = "Corrected By is required";
      setIsFormValid(false);
    }
    if (!updatedFormData.dateCorrected) {
      errors.dateCorrected = "Date Corrected is required";
      setIsFormValid(false);
    }
    if (!updatedFormData.comments) {
      errors.comments = "Comments is required";
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    setValidationErrors(errors);
  };

  const handleAdvanceInputChange = (e: any) => {
    const { name, value } = e.target;
    setAdvInputs((prevAdvInputs: any) => ({
      ...prevAdvInputs,
      [name]: value,
    }));
  };

  const handleCheckInputChange = (e: any) => {
    const { name, checked } = e.target;
    let updatedDelFormList = [...deleteFormList];
    let updatedselectedFormList = [...selectedFormData];

    if (checked) {
      if (!updatedDelFormList.includes(name)) {
        updatedDelFormList.push(name);
        let data = formsData.filter((form) => {
          return form._id === name;
        });
        updatedselectedFormList.push(data[0]);
      }
    } else {
      updatedDelFormList = updatedDelFormList.filter((id) => id !== name);
      updatedselectedFormList = updatedselectedFormList.filter(
        (form) => form._id !== name
      );
    }

    setDelFormList(updatedDelFormList);
    setselectedFormData(updatedselectedFormList);
    const updatedFormsData = formsData.map((form) => ({
      ...form,
      isChecked: updatedDelFormList.includes(form._id),
    }));
    let selectAllFormCheck = updatedFormsData.every(
      (obj: any) => obj.isChecked
    );
    setSelectedAllForm(selectAllFormCheck);
    setFormsData(updatedFormsData);
  };

  const handleAllCheckInputChange = (e: any) => {
    const { name, checked } = e.target;
    setSelectedAllForm(checked);
    let updatedDelFormList = [...deleteFormList];
    let updatedselectedFormList = [...selectedFormData];
    if (checked) {
      let selectAllFormIDs = formsData.map(({ _id }) => _id);
      let selectAllFormIDData = formsData.map((form) => ({
        ...form,
        isChecked: true,
      }));
      updatedDelFormList = [
        ...updatedDelFormList,
        ...selectAllFormIDs.filter((d) => deleteFormList.indexOf(d) == -1),
      ];
      updatedselectedFormList = [
        ...updatedselectedFormList,
        ...selectAllFormIDData.filter(
          (d) => deleteFormList.indexOf(d._id) == -1
        ),
      ];
      setFormsData(selectAllFormIDData);
    } else {
      let formDataIDS = formsData.map(({ _id }) => _id);
      updatedDelFormList = updatedDelFormList.filter(
        (form) => !formDataIDS.includes(form)
      );
      updatedselectedFormList = updatedselectedFormList.filter(
        (form) => !formDataIDS.includes(form._id)
      );
      const updatedFormsData = formsData.map((form) => ({
        ...form,
        isChecked: false,
      }));
      setFormsData(updatedFormsData);
    }
    setDelFormList(updatedDelFormList);
    setselectedFormData(updatedselectedFormList);
  };

  const handleDeleteForm = async () => {
    setDisable(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("authtoken");
        const response = await axios.post(
          `${BASE_URL}/formsubmit/deleteByFormId`,
          { formId: deleteFormList.toString() },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success === true) {
          fetchForms();
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
          setDelFormList([]);
          setselectedFormData([]);
          setSelectedAllForm(false);
          setDisable(false);
          //setFormsData(data)
        } else {
          console.log("error");
          setDisable(false);
        }
      } else {
        setDisable(false);
      }
    });
  };

  const fetchFormData = async (form: any) => {
    const token = localStorage.getItem("authtoken") || "";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const parentId = form.parentId;
      const valuesId = form._id;

      const fetchFormResponse = await axios.get(
        `${BASE_URL}/form/${parentId}`,
        config
      );
      const fetchValuesResponse = await axios.get(
        `${BASE_URL}/formsubmit/${valuesId}`,
        config
      );

      if (
        fetchFormResponse?.data?.success &&
        fetchValuesResponse?.data?.success
      ) {
        const htmlContent = generatePDF(
          fetchFormResponse?.data?.form?.values,
          fetchValuesResponse?.data?.form
        );

        const doc = new jsPDF();
        const container = document.createElement("div");
        container.innerHTML = htmlContent;
        document.body.appendChild(container);

        await new Promise<void>((resolve, reject) => {
          doc.html(container, {
            callback: (doc) => {
              resolve();
            },
            margin: [10, 10, 10, 10],
            autoPaging: "text",
            x: 0,
            y: 0,
            width: 190,
            windowWidth: 675,
          });
        });

        document.body.removeChild(container);

        const pdfBlob = doc.output("blob");
        form.title = form.title.replace(/\//g, " ");

        let dateInMillisecs = Date.now();
        let dateInSecs = Math.round(dateInMillisecs / 1000);

        return {
          name: `${form.title}/${form.title} - ${form.username} - ${dateInSecs}`,
          content: pdfBlob,
        };
      } else {
        console.error(
          "PDF generation failed: Form data could not be fetched successfully."
        );
        return null;
      }
    } catch (error) {
      console.error("Error generating or downloading PDF:", error);
      return null;
    }
  };

  const handleDownload = async () => {
    setDisable(true);
    const spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.style.display = "block";
    }
    const zip = new JSZip();
    try {
      for (const formId of selectedFormData) {
        const formData = await fetchFormData(formId);
        if (formData) {
          zip.file(`${formData.name}.pdf`, formData.content);
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "forms.zip");
      setDelFormList([]);
      setselectedFormData([]);
      setSelectedAllForm(false);
      let data = formsData.map((form: any) => {
        form.isChecked = false;
        return form;
      });
      showToast("Forms Downloaded Successfully!", "success");
    } catch (error) {
      console.error("Error creating zip file:", error);
    } finally {
      if (spinner) {
        spinner.style.display = "none";
      }
      setDisable(false);
    }
  };

  return (
    <div>
      {role === "superadmin" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <button
              className="blue"
              disabled={disable}
              onClick={openCoopsModal}
              style={{
                float: "right",
                cursor: disable ? "not-allowed" : "pointer",
                backgroundColor: disable ? "gray" : "#0e1937"
              }}
            >
              Coop List
            </button>
          </Grid>
        </Grid>
      ) : null}

      <form>
        <Box
          sx={{
            margin: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Autocomplete
                options={
                  formNames ? formNames.map((form: any) => form?.name) : []
                }
                disabled={disable}
                getOptionLabel={(option) => option || ""}
                value={searchFromVal}
                onChange={(event, newValue) => handleFormChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Form Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                label="Username"
                name="username"
                variant="outlined"
                value={searchUserVal}
                fullWidth
                onChange={(e) => handleUserChange(e.target.value)}
                disabled={disable}
              />
            </Grid>
          </Grid>

          <button
            className="blue"
            onClick={handleSearch}
            disabled={disable}
            style={{ backgroundColor: disable ? "gray" : "#0e1937" }}
          >
            Search
          </button>
          <button
            className="blue"
            onClick={handleClearSearch}
            disabled={disable}
            style={{ backgroundColor: disable ? "gray" : "#0e1937" }}
          >
            Clear
          </button>
        </Box>
        <Box
          sx={{
            margin: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <TextField
                id="fromDate"
                label="From Date"
                type="date"
                fullWidth
                required
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  if (new Date(e.target.value) > new Date(toDate)) {
                    setToDate(""); // Reset toDate if fromDate is greater
                  }
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  mb: 1,
                }}
                InputProps={{
                  readOnly: !isEditable,
                  inputProps: {
                    max: toDate || today, // max date is toDate or today
                  },
                }}
              />
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}>
              <TextField
                id="toDate"
                label="To Date"
                type="date"
                fullWidth
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  if (new Date(e.target.value) < new Date(fromDate)) {
                    setFromDate(""); // Reset fromDate if toDate is less
                  }
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  mb: 1,
                }}
                required
                InputProps={{
                  readOnly: !isEditable,
                  inputProps: {
                    min: fromDate, // min date is fromDate
                    max: today, // max date is today
                  },
                }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              {searchFromVal === "Hazard Recognition" ||
                searchFromVal === "Vehicle/Trailer Inspection" || !searchFromVal ? (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '16px', }} >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isActionNeeded}
                        onChange={handleActionNeededToggle}
                        color="primary"
                      />}
                    label={<Typography>{isActionNeeded ? 'Action Required' : 'Action Required'}</Typography>} />

                </Box>) : null}
            </Grid>
          </Grid>
        </Box>
      </form>
      <div>


        <div>
          {" "}
          {showCoopsModal && (
            <div className="modal">
              <div className="modal-content">
                <div>
                  <span className="close" onClick={closeCoopsModal}>
                    &times;
                  </span>
                </div>

                <div style={{ padding: "10px", textAlign: "left" }}>
                  <input
                    type="text"
                    placeholder="Search by name or number..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: "50%", padding: "10px" }}
                  />
                  <button
                    className="blue coopListBtn"
                    onClick={handleSearchEmpty}
                    style={{ margin: "10px" }}
                  >
                    Clear
                  </button>
                </div>
                {selectedCoopKeys.length !== 0 ? (
                  <div className="selectedCoopNumList">
                    {selectedCoopKeys.map((coop, index) => (
                      <span className="selectedCoopNum" key={index}>
                        {coop}
                      </span>
                    ))}
                  </div>
                ) : null}
                <div
                  className="modal-body"
                  style={{ height: "570px", overflowY: "auto" }}
                >
                  <ul
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: 0,
                    }}
                  >
                    <li className="coopNumberList">
                      <label
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={isAllSelected}
                          onChange={handleAllCheckboxChange}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                        <span style={{ marginLeft: 5 }}>All</span>
                      </label>
                    </li>
                    {filteredCoopData.map((coop: any, index: any) => (
                      <li key={coop.systemNumber} className="coopNumberList">
                        <label
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              selectedCoopKeys.includes(coop.systemNumber)
                            }
                            onChange={() =>
                              handleCheckboxChange(coop.systemNumber)
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <span
                            style={{
                              marginLeft: 5,
                            }}
                          >{`${coop.systemName}-(${coop.systemNumber})`}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        {showAdvance ? (
          <Button
            variant="text"
            style={{ float: "right" }}
            onClick={handleShowAdvanceInputs}
          >
            Advance Search
          </Button>
        ) : null}
      </div>
      &nbsp;
      <div>
        {showInputs ? (
          <Grid container display={"flex"} flexDirection={"row"}>
            {Object.keys(advInputs).map((key, index) => (
              <Grid item xs={5} sm={5} md={5} lg={5} m={1} key={key}>
                <TextField
                  label={capitalizeFirstLetter(key)}
                  name={key}
                  variant="outlined"
                  value={advInputs[key]}
                  fullWidth
                  onChange={handleAdvanceInputChange}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </div>
      &nbsp;
      <br></br>
      {deleteFormList.length !== 0 ? (
        <button
          className="btn btn-danger float-right mb-2"
          onClick={() => {
            handleDeleteForm();
          }}
        >
          Delete Forms
        </button>
      ) : null}
      &nbsp;
      {deleteFormList.length !== 0 ? (
        <button
          className="btn btn-secondary float-right mb-2"
          onClick={() => {
            handleDownload();
          }}
        >
          Download
        </button>
      ) : null}
      <div id="spinner" style={spinnerStyle}></div>
      <ToastContainer />
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="all"
                  checked={(selectedAllForm && deleteFormList.length !== 0) || false}
                  onChange={handleAllCheckInputChange}
                />
              </th>
              <th>Form Name</th>
              <th>User Name</th>
              <th>Date</th>
              <th>Options</th>
              <th></th>
            </tr>
          </thead>
          {formsData?.length > 0 ? (
            <tbody>
              {formsData.map((form) => (
                <tr key={form?._id}>
                  <td>
                    <input
                      type="checkbox"
                      name={form?._id}
                      checked={form?.isChecked || false}
                      onChange={handleCheckInputChange}
                    />
                  </td>
                  <td>{form?.title}</td>
                  <td>{form?.username}</td>
                  <td>{form?.timezone ? convertToTimeZone(form?.date,form?.timezone):getLocalTime(form?.date) }</td>
                  <td>
                    <Button
                      onClick={handlePdf(form)}
                      disabled={disable}
                      className="download-btn"
                      sx={{ color: disable ? "gray" : "#0e1937" }}
                    >
                      Pdf
                    </Button>
                    <Button
                      onClick={handleCsv(form)}
                      disabled={disable}
                      className="download-btn"
                      sx={{ color: disable ? "gray" : "#0e1937" }}
                    >
                      Csv
                    </Button>
                  </td>
                  <td>
                    {form.title === "Hazard Recognition" ||
                      (form.title === "Vehicle/Trailer Inspection" &&
                        (form.values.indexOf("needsAttention") !== -1 ||
                          form.actionRequiredCondition == true)) ? (
                      <Button
                        variant="outlined"
                        onClick={() => handleAROpen(form)}
                        style={{
                          backgroundColor:
                            typeof form?.actionRequired === "object"
                              ? "green"
                              : "red",
                          color: "white",
                        }}
                      // disabled={disable}
                      >
                        Action Required
                      </Button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  {loading ? (
                    <CircularProgress sx={{ color: "#0e1937 !important" }} />
                  ) : (
                    "No forms have been submitted."
                  )}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          <Button
            onClick={handlePrevious}
            disabled={page === 1 || disable}
            className="footer-btn"
            style={{ cursor: page === 1 ? "not-allowed" : "pointer" }}
          >
            Prev
          </Button>
          <Button
            onClick={handleNext}
            disabled={page === totalPages || disable}
            className="footer-btn"
          >
            Next
          </Button>
        </div>
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="modal-modal-title">Action Required Details</h2>
          {!isEditable ? (
            <Typography variant="subtitle1">
              Action Required Taken by :{" "}
              <span style={{ color: "green" }}>
                {capitalizeFirstLetter(formData?.submittedby)}
                {/* {formData?.submittedby} */}
              </span>
            </Typography>
          ) : null}
          &nbsp;
          <TextField
            fullWidth
            id="order"
            label="Work Order/Service Order#"
            variant="outlined"
            value={formData?.order}
            onChange={handleInputChange}
            sx={{ mb: 1 }}
            required={true}
            InputProps={{
              readOnly: !isEditable,
            }}
          />
          {validationErrors.order && (
            <Typography sx={{ mb: 1, color: "red" }}>
              {validationErrors.order}
            </Typography>
          )}
          <TextField
            fullWidth
            id="actionTaken"
            label="Corrective Action Taken"
            variant="outlined"
            multiline
            rows={4} // Adjust the number of rows as needed
            value={formData?.actionTaken}
            onChange={handleInputChange}
            sx={{ mb: 1 }}
            required
            InputProps={{
              readOnly: !isEditable,
            }}
          />
          {validationErrors.actionTaken && (
            <Typography sx={{ mb: 1, color: "red" }}>
              {validationErrors.actionTaken}
            </Typography>
          )}
          <TextField
            fullWidth
            id="correctedBy"
            label="Corrected By"
            variant="outlined"
            value={formData?.correctedBy}
            onChange={handleInputChange}
            sx={{ mb: 1 }}
            required
            InputProps={{
              readOnly: !isEditable,
            }}
          />
          {validationErrors.correctedBy && (
            <Typography sx={{ mb: 1, color: "red" }}>
              {validationErrors.correctedBy}
            </Typography>
          )}
          <TextField
            id="dateCorrected"
            label="Date Corrected"
            type="date"
            fullWidth
            value={formData?.dateCorrected}
            onChange={handleInputChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 1, width: "50%" }}
            required
            InputProps={{
              readOnly: !isEditable,
            }}
          />
          {validationErrors.dateCorrected && (
            <Typography sx={{ mb: 1, color: "red" }}>
              {validationErrors.dateCorrected}
            </Typography>
          )}
          <TextField
            fullWidth
            id="comments"
            label="Comments"
            variant="outlined"
            multiline
            rows={4}
            value={formData?.comments}
            onChange={handleInputChange}
            sx={{ mb: 1 }}
            required
            InputProps={{
              readOnly: !isEditable,
            }}
          />
          {validationErrors.comments && (
            <Typography sx={{ mb: 1, color: "red" }}>
              {validationErrors.comments}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              className="footer-btn"
            >
              Close
            </Button>
            {isEditable ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: isFormValid ? "#0e1937 !important" : "red",
                }}
                onClick={() => {
                  if (isFormValid) {
                    console.log("isFormValid", isFormValid);

                    // handleCloseModal();
                    handleARSend(formData);
                  } else {
                    alert("Please fill in all required fields.");
                  }
                }}
                disabled={!isFormValid}
              >
                Approve
              </Button>
            ) : null}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SubmissionList;
